import axios from "axios";
import Cookies from "js-cookie";
import router from '@/router'
import { Notification, MessageBox, Message } from "element-ui"
import errorCode from '@/utils/errorCode'
axios.defaults.timeout = 150*1000;
// axios.defaults.baseURL = 'http://47.94.103.35:5883'
//axios.defaults.baseURL = 'http://localhost:5883'
let noToken = ["/sys/login", "/sys/getCode"],
  url;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.interceptors.request.use(
  config => {
    if (!noToken.includes(url)) {
      config.headers.token = Cookies.get("token");
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  res => {
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode["default"];
    if (code === 401 && !location.href.includes('login')){
      if(document.getElementsByClassName('el-message').length === 0){
        Message({
          message: '登录状态已过期，或者该账号在其他地方登录，请重新登录！',
          type: "error"
        });
        setTimeout(() => {
          router.push({
            path: '/login'
          })
        }, 500)
      }
    } else if (code === 500) {
      if(document.getElementsByClassName('el-message').length === 0){
        Message({
          message: msg,
          type: "error"
        });
      }
      return Promise.reject(new Error(msg));
    } else if (code !== 200) {
      Notification.closeAll()
      Notification.error({
        title: msg
      });
      return Promise.reject("error");
    } else {
      return res;
    }
  },
  error => {
    console.log("err" + error);
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    if(document.getElementsByClassName('el-message').length === 0){
      Message({
        message: message,
        type: "error",
        duration: 5 * 1000
      });
    }
    return Promise.reject(error);
  }
)
export default {
  get(url, params) {
    url = url;
    return new Promise((resolve, reject) => {
      axios
        .get(url, {params})
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  post(url, params = {}, options = {}) {
    url = url;
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, options)
        .then(res => {
          resolve(res.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
